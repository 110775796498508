

.nav-link {
  max-width: 113.9px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

.navbar-brand .nav-bar {
  padding: 0px;
}

nav {
  min-height: 3.5rem;
  background-color: black !important;
  z-index: 1033;
}

.nav-link span{
  display: none;
  padding-top:0.5rem;
}


@media only screen and (max-width: 567px) {
  #searchmodal {
    display: none !important;
  }
  .nav-link {
    display: block !important;
  }
  #search {
    display: none !important;
  }
  .nav-link span{
    display: block;
  }
  .nav-link svg{
    display: none;
  }
  /* #navigation{
    max-width: 100%;
  } */
}

@media only screen and (max-width: 1024px) {
  #navigation{
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .nav-link {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.svg-rotate {
  transform: rotate(180deg);
}