$sidebar-bg-color: #0c1e35;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(#0c1e35, 0.95);
$sidebar-color: #b3b8d4;
$highlight-color: #dee2ec;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;

@import '~react-pro-sidebar/dist/scss/styles.scss';

.pro-sidebar{
  overflow: auto;
  max-height: calc(100vh - 3.5rem);
  width: 320px;
}
.card{
  border: none;
}
.Button{
  width: 100%;
  margin: 3px;
  border: none;
  height: 30px;
  text-align: left;

}

.ButtonVideo{
  width: 85%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

button:focus {outline:0;}

.Bselected{
  background-color: rgb(220, 220, 220) ;;
}
.Vselected{
  background-color: rgb(0, 122, 255);
  color: rgb(255,255,255);
}
.Bnoselected{
  background-color: rgb(245, 245, 245) ;
}

.visible{
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s linear;
}

.invisible{
  visibility: hidden;
  opacity: 0;
  height: 0;
  // transition: visibility 0s 2s, opacity 2s linear;

}

.libcontainer {
  position: relative;
  top: 3.5rem;
  display: flex;
  padding: 0px;
  background-color: rgb(20,20,20);

  .card-body{
  background-color: rgb(20,20,20);
  }

  .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
  }


  .channels{
    width: 350px;
    height: calc(100vh - 4rem)  ;
    padding: 10px;
    overflow: auto;
  }
  .newlib {
    margin: 10px;
    cursor: pointer;
    width: 35px;
    height: 35px;

    :hover {
      box-shadow: 4px 4px 16px rgb(0 0 0 / 20%);
      transform: scale3d(1.01, 1.01, 1.01);
    }
  }
  .Cardcontainer {
    margin-left: auto;
    margin-right: auto;
    // min-width: 250px;
    min-width: 800px;
    width: 70%;
  }

  
}

@media (max-width: $breakpoint-md) {
  .btn-toggle {
    display: flex;
  }
}

.card-body {
  padding: 0;
  .card-header {
  }

  .video {
    border: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    // max-height: 640px;
  }
  
  .card-section {
    .card-section-header {
      font-size: medium;
      padding: 5px;
    }
    .card-section-content {
      background-color: white;
      font-size: small;
      padding: 5px;
      min-height: 100px;
    }
  }
  .card-ops {
    display: flex;
    margin: 1rem;
    svg {
      width: 20px;
      height: 20px;
      margin-right: 1.2rem;
      cursor: pointer;

      :hover {
        box-shadow: 4px 4px 16px rgb(0 0 0 / 20%);
        // transform: scale3d(1.01, 1.01, 1.01);
      }
    }
  }
}
